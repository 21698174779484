export class CreateClaimModal {
    Audit_year: number;
    Auditor_Id: any;
    Claim_Type: any;
    Claim_Holder: any;
    Claim_Status: any;
    Root_Cause: any;
    Claim_Reason: any;
    WM_Claim_Reason: any;
    Claim_Explanation: any;
    Buyer_Id: number;
    Buyer_Name: any;
    Supplier_Num: any;
    Supplier_Name: any;
    DMM_Id: any;
    DMM_Name: any;
    Supplier_Address: any;
    Format: any;
    Original_Claim_Amt: number;
    Currency: any;
    action: any;
    Adjustments: number;
    Adjusted_Claim_Amt: number;
    Supplier_Remark: any;
    Auditor_Findings: any;
    Dept_Num: any;
    Area_of_Responsibility: any;
    Time_Period: any;
    Conversion_Likelihood: any;
    SAMS_Cat: any;
    Additional_Note: any;
    concept: any;
    scope: any;
    country: any;
    creation_by: any;
    updated_date: any;
    updated_by: any;
    Audit_Type: any;
    Record_Id: any;

    UPLOAD_FILE: any;
    CLAIM_ID: number;
    WMCLAIM_ID: number;
    APP_CLAIM_NUMBER: any;
    DATE_AUDITED: number;

    Tax_IVA: number;
    Tax_EPS: number;
    user_id: string;
    Status: any;
    No_Follow_up_Actions: number;  
    Last_Follow_up_Date: any;
    Last_Action: any;
    Monitoring_Log: any;
    Applied_by_Threshold: any;
    Shp_Dt_To_Supplier: any;
        
}
